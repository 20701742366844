import React from 'react';
import './App.css';

function App() {
  return (
    <div className="module-container">
      <div className="step">
        <div className="step-number">01</div>
        <div className="step-title">Discovery</div>
        <div className="step-description">
          We start by getting an understanding of your vision. That includes everything from your technical requirements to the needs driving this initiative. The more we know, the better we can develop a bespoke plan that will not just meet, but exceed your expectations.
        </div>
      </div>
      <div className="step">
        <div className="step-number">02</div>
        <div className="step-title">Consultation & product planning</div>
        <div className="step-description">
          Once we understand your vision, we draw from our decades of experience to strategize the optimal approach to address your specific needs. This could include consulting on technologies, value engineering, an emphasis on lean development, and/or designing a product roadmap.
        </div>
      </div>
      <div className="step">
        <div className="step-number">03</div>
        <div className="step-title">Wireframing & design</div>
        <div className="step-description">
          We go through a highly collaborative design phase before we write a single line of code. You’ll work with our expert UI/UX designers to create beautiful, intuitive wireframes and visual designs following our heuristic design principles.
        </div>
      </div>
    </div>
  );
}

export default App;
